import React, { useEffect } from 'react';
import {
  Box,
  Container,
  Grid,
  Typography,
  Card,
  CardContent
} from '@mui/material';
import CASELWheelImage from '../assets/casel-wheel.png';
import { makeStyles } from '@mui/styles';
import { theme } from '../providers';
import { AuthProvider } from '../providers';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles(() => ({
  customContainer: {
    width: '100%',
    margin: '16px !important',
    flexGrow: 1,
    display: 'flex',
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center'
  },
  card: {
    [theme.breakpoints.up('sm')]: {
      minWidth: '350px',
      margin: '20px auto'
    },
    [theme.breakpoints.down('sm')]: {
      minWidth: '120px',
      margin: '20px 20px 0 20px',
      textAlign: 'center',
      justifyContent: 'center',
      alignItems: 'center',
      flexGrow: 1,
      display: 'flex'
    }
  },
  competencyCards: {
    display: 'flex'
  },
  competencyGrid: {
    [theme.breakpoints.up('md')]: {
      marginTop: '24px'
    }
  }
}));

export const CASELPage: React.FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const validateSession = async () => {
    try {
      const sessionValid = await AuthProvider.validateSession();
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    validateSession();
    if (!AuthProvider.isAuthenticated()) {
      navigate('/');
    }
  }, [navigate]);

  return (
    <Container className={classes.customContainer}>
      <Box mt={4}>
        <h5
          style={{
            color: '#3a5169',
            fontSize: 32,
            textDecoration: 'underline',
            margin: '0'
          }}
        >
          CASEL Competency Overview
        </h5>
        <Grid container spacing={2}>
          <Grid item xs={12} md={16} className={classes.competencyGrid}>
            <Card elevation={3} className={classes.card}>
              <CardContent>
                <img
                  src={CASELWheelImage}
                  alt='CASEL Wheel'
                  style={{
                    width: '100%',
                    maxWidth: '400px',
                    margin: '20px auto'
                  }}
                />
              </CardContent>
            </Card>
          </Grid>
          <Grid container spacing={2} className={classes.competencyCards}>
            <CompetencySummary
              title='Self-Awareness'
              description='Self-awareness is the ability to recognize and understand your own emotions, thoughts, and values. It involves introspection and self-reflection to gain insight into your motivations and behaviors.'
            />
            <CompetencySummary
              title='Self-Management'
              description='Self-management is the skill of regulating your emotions and behaviors. It includes impulse control, stress management, and the ability to set and work toward personal goals.'
            />
          </Grid>
          <Grid container spacing={2} className={classes.competencyCards}>
            <CompetencySummary
              title='Social Awareness'
              description='Social awareness is the capacity to empathize and understand the perspectives and emotions of others. It involves active listening, recognizing social cues, and demonstrating empathy.'
            />
            <CompetencySummary
              title='Relationship Skills'
              description='Relationship skills refer to the ability to build and maintain healthy relationships with others. This competency includes effective communication, conflict resolution, and collaboration.'
            />
          </Grid>
          <Grid
            container
            spacing={2}
            sx={{ textAlign: 'center', justifyContent: 'center' }}
          >
            <CompetencySummary
              title='Responsible Decision-Making'
              description='Responsible decision-making involves considering ethical and moral values when making choices. It includes assessing potential consequences and making decisions that promote well-being for oneself and others.'
            />
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

// Component for each competency summary
const CompetencySummary: React.FC<{ title: string; description: string }> = ({
  title,
  description
}) => {
  const classes = useStyles();
  return (
    <Grid item xs={12} sm={6}>
      <Card elevation={3} className={classes.card}>
        <CardContent>
          <Typography variant='h6' align='center' gutterBottom>
            {title}
          </Typography>
          <Typography>{description}</Typography>
        </CardContent>
      </Card>
    </Grid>
  );
};
