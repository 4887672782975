import React, { useEffect } from 'react';
import Typography from '@mui/material/Typography';
import { AuthProvider } from '../providers';
import {
  Box,
  Grid,
  Stack,
  Button,
  useMediaQuery,
  List,
  ListItem,
  ListItemText,
  Divider,
  Card,
  CardContent
} from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { theme } from '../providers';

const useStyles = makeStyles(() => ({
  mainContent: {
    width: '80%',
    padding: '16px',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'left',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      maxWidth: '85%',
      margin: '00px 0 0 150px'
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: '85%',
      margin: '0 0 0 10%'
    }
  },
  card: {
    [theme.breakpoints.up('sm')]: {
      padding: '0',
      minWidth: '350px',
      margin: '20px auto',
      minHeight: '250px',
      textAlign: 'center',
      '&:hover': {
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.5)'
      }
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0',
      minWidth: '150px',
      margin: '20px 20px 0 20px',
      textAlign: 'center',
      justifyContent: 'center',
      alignItems: 'center',
      flexGrow: 1,
      display: 'flex',
      '&:hover': {
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.5)'
      }
    }
  },
  competencyCards: {
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      margin: '0 0 0 40px'
    },
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      margin: '0'
    }
  },
  competencyGrid: {
    [theme.breakpoints.up('md')]: {
      marginTop: '24px'
    }
  }
}));

export const ProgramPage: React.FC = () => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width:600px)');
  const classes = useStyles();
  const isAuthenticated = AuthProvider.isAuthenticated();

  const validateSession = async () => {
    try {
      const sessionValid = await AuthProvider.validateSession();
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    validateSession();
    if (!isAuthenticated) {
      navigate('/');
    }
  }, [isAuthenticated, navigate]);

  return (
    <Box className={classes.mainContent}>
      <Stack spacing={4} sx={{ width: '100%', justifyContent: 'center' }}>
        <Grid>
          <h5
            style={{
              color: '#3a5169',
              fontSize: 32,
              textDecoration: 'underline',
              textAlign: 'center',
              margin: '0'
            }}
          >
            Early Childhood Program Guide
          </h5>
        </Grid>
        <Grid>
          <h6
            style={{
              color: '#3a5169',
              fontSize: 26,
              textDecoration: 'underline',
              margin: '0'
            }}
          >
            Overview
          </h6>
        </Grid>
        <Grid>
          <Typography paragraph sx={{ textAlign: 'left' }}>
            The Life Lessons early childhood program guide is designed to help
            educators lay the foundation for the development of strong social
            emotional skills that support all aspects of student learning,
            throughout a student's journey.
          </Typography>
          <Typography paragraph sx={{ textAlign: 'left' }}>
            Early childhood is an important time to support children’s social
            emotional growth. Guided by the science of learning and development,
            we have created a program that allows teachers, students and parents
            to engage in developmental activities with our SEL lessons, The
            Outpost™, to ensure students have opportunities to exercise SEL
            competencies in early childhood. The Life Lessons program guide will
            provide educators with the tools to help students:
          </Typography>
          <List
            sx={{
              fontSize: '20px',
              padding: '0 0 0 20px',
              margin: '1px',
              listStyleType: 'disc'
            }}
          >
            <ListItem
              sx={{
                fontSize: '20px',
                padding: '0 0 0 10px',
                margin: '1px',
                display: 'list-item'
              }}
            >
              <ListItemText primary='Recognize and label “basic” emotions (e.g., joy, sadness, anger, surprise).' />
            </ListItem>
            <ListItem
              sx={{
                fontSize: '20px',
                padding: '0 0 0 10px',
                margin: '1px',
                display: 'list-item'
              }}
            >
              <ListItemText primary='Understand the connection between various social situations and underlying emotions.' />
            </ListItem>
            <ListItem
              sx={{
                fontSize: '20px',
                padding: '0 0 0 10px',
                margin: '1px',
                display: 'list-item'
              }}
            >
              <ListItemText primary='Engage in interactive activities to support perspective taking, flexible thinking, problem solving, and empathy.' />
            </ListItem>
            <ListItem
              sx={{
                fontSize: '20px',
                padding: '0 0 0 10px',
                margin: '1px',
                display: 'list-item'
              }}
            >
              <ListItemText primary='Self-reflect on emotions and practice self-awareness.' />
            </ListItem>
            <ListItem
              sx={{
                fontSize: '20px',
                padding: '0 0 0 10px',
                margin: '1px',
                display: 'list-item'
              }}
            >
              <ListItemText primary='Discuss strategies to manage emotions.' />
            </ListItem>
            <ListItem
              sx={{
                fontSize: '20px',
                padding: '0 0 0 10px',
                margin: '1px',
                display: 'list-item'
              }}
            >
              <ListItemText primary='Initiate and maintain positive peer interactions.' />
            </ListItem>
          </List>
          <Divider sx={{ margin: '20px' }} />
          <Grid container spacing={2} className={classes.competencyCards}>
            <UnitSummary
              id='1'
              title='Naming Emotions'
              description='This Unit is designed to introduce students to Paul Ekman’s “six basic emotions” including joy, sadness, disgust, anger, surprise and fear. Students will have an opportunity to practice identifying and labeling emotions. This Unit will prepare students to begin to understand these emotions in context through various scenarios.'
            />
            <UnitSummary
              id='2'
              title='Understanding Our Emotions'
              description='This Unit is designed to help students to better understand their own emotions. Students will have an opportunity to practice identifying and labeling emotions that they experience, as well as strategies for communicating those emotions. This Unit will help students practice the skills to begin to recognize emotions in others, setting the stage for the development of empathy.'
            />
          </Grid>
          <Grid container spacing={2} className={classes.competencyCards}>
            <UnitSummary
              id='3'
              title='Challenging Scenarios and Emotions in Context'
              description='This Unit is designed to help students to better understand how various scenarios, contexts and situations may elicit different kinds of emotions. Students will interact with the Outpost™ lessons and have an opportunity to practice identifying and discussing challenging scenarios that one may encounter in a school setting.'
            />
            <UnitSummary
              id='4'
              title='Emotion Regulation Strategies and Empathy'
              description='This Unit is designed to provide students with opportunities to kick-start the emotion regulation process by calming themselves using breathing techniques, and guiding their thoughts and behavior with the use of self-talk. This Unit is also designed to support students’ perspective-taking skills as they engage with one another to practice recognizing others emotions and providing an empathetic response.'
            />
          </Grid>
          <Grid
            container
            spacing={2}
            sx={{ textAlign: 'center', justifyContent: 'center' }}
          >
            <UnitSummary
              id='5'
              title='Putting It All Together'
              description='Congratulations! You have made it to Unit 5 - now it is time to put it all together! This Unit is designed to reinforce the skills students exercised in Units 1-4. This module will give students the chance to practice identifying and labeling emotions, describing how the emotions are experienced in their bodies, tying emotions to events and situations, recognizing emotions in others, and practicing gratitude.'
            />
          </Grid>
        </Grid>
      </Stack>
    </Box>
  );
};

// Component for each Unit
const UnitSummary: React.FC<{
  id: string;
  title: string;
  description: string;
}> = ({ id, title, description }) => {
  const classes = useStyles();
  return (
    <Grid item xs={12} sm={6} sx={{ padding: 0 }}>
      <Link
        to={`/unit/${id}`}
        style={{
          textDecoration: 'none',
          cursor: 'pointer',
          display: 'block',
          transition: 'background-color 0.3s ease'
        }}
      >
        <Card elevation={3} className={classes.card}>
          <CardContent sx={{ padding: '0 !important' }}>
            <Typography
              variant='h6'
              align='center'
              gutterBottom
              sx={{
                backgroundColor: '#4e6e8e',
                padding: '12px 12px 12px 15px',
                marginBottom: '10px',
                color: 'white',
                fontSize: '20px',
                fontWeight: 'bold',
                textAlign: 'left'
              }}
            >
              <span
                style={{
                  fontWeight: '900',
                  textDecoration: 'underline',
                  fontSize: '22px'
                }}
              >
                Unit {id}
              </span>
              : {title}
            </Typography>
            <Typography
              sx={{
                marginTop: '10px',
                padding: '10px 15px',
                textAlign: 'left'
              }}
            >
              {description}
            </Typography>
          </CardContent>
        </Card>
      </Link>
    </Grid>
  );
};
