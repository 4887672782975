import { apiUrl, baseCookieDomain } from '../config';
import { AuthProvider } from './AuthProvider';

interface AxiosResponse {
  status: number;
  data: any;
  statusText: string;
}

export const LessonProvider = {
  /**
   * Get lesson history for user
   *
   * @returns JSON data containing lesson history
   * @throws Error on http errors or failed attempts
   */
  getLessonHistory: async () => {
    let response = {} as AxiosResponse;
    const axiosRequest = await AuthProvider.getAxiosInstance()
      .get('/api/v1/lesson_instances/me')
      .then(function (a_response: any) {
        response = a_response;
      })
      .catch(function (error) {
        throw new Error(error);
      });

    if (response.status === 500) {
      throw new Error('Bad Request');
    }
    const data = response.data;
    if (response.status > 400 && response.status < 500) {
      throw response.statusText;
    }
    return data;
  },
  /**
   * Get game history for user
   *
   * @returns JSON data containing game history
   * @throws Error on http errors or failed attempts
   */
  getGameHistory: async () => {
    let response = {} as AxiosResponse;
    const axiosRequest = await AuthProvider.getAxiosInstance()
      .get('/api/v1/game/list')
      .then(function (a_response: any) {
        response = a_response;
      })
      .catch(function (error: any) {
        console.log(error);
      });

    if (response.status === 500) {
      throw new Error('Internal server error');
    }

    const data = response.data;
    if (response.status > 400 && response.status < 500) {
      if (data.detail) {
        throw data.detail;
      }
      throw data;
    }

    return data;
  },
  /**
   * Get game code by id
   *
   * @returns string representing game code
   * @throws Error on http errors or failed attempts
   */
  getGameKey: async (id: number) => {
    let response = {} as AxiosResponse;
    const axiosRequest = await AuthProvider.getAxiosInstance()
      .get(`/api/v1/game/key/${id}`)
      .then(function (a_response: any) {
        response = a_response;
      })
      .catch(function (error: any) {
        console.log(error);
      });

    if (response.status === 500) {
      throw new Error('Internal server error');
    }

    const data = response.data;
    if (response.status > 400 && response.status < 500) {
      if (data.detail) {
        throw data.detail;
      }
      throw data;
    }

    return data;
  },
  /**
   * Get classroom aggregation
   *
   * @returns JSON data containing game history
   * @throws Error on http errors or failed attempts
   */
  getClassroomSurveys: async () => {
    let response = {} as AxiosResponse;
    const axiosRequest = await AuthProvider.getAxiosInstance()
      .get(`/api/v1/game/classroom/surveys`)
      .then(function (a_response: any) {
        response = a_response;
      })
      .catch(function (error: any) {
        console.log(error);
      });

    if (response.status === 500) {
      throw new Error('Internal server error');
    }

    const data = response.data;
    if (response.status > 400 && response.status < 500) {
      if (data.detail) {
        throw data.detail;
      }
      throw data;
    }

    return data;
  },
  /**
   * Get game summary for game
   *
   * @returns JSON data containing game summary
   * @throws Error on http errors or failed attempts
   */
  getGameSummary: async (id: string) => {
    let response = {} as AxiosResponse;
    const axiosRequest = await AuthProvider.getAxiosInstance()
      .get(`/api/v1/game/player_list/${id}`)
      .then(function (a_response: any) {
        response = a_response;
      })
      .catch(function (error: any) {
        console.log(error);
      });

    if (response.status === 500) {
      throw new Error('Internal server error');
    }

    const data = response.data;
    if (response.status > 400 && response.status < 500) {
      if (data.detail) {
        throw data.detail;
      }
      throw data;
    }

    return data;
  },
  /**
   * Get lesson summary of lesson instance
   *
   * @returns JSON data containing lesson summary
   * @throws Error on http errors or failed attempts
   */
  getLessonSummary: async (id: number, multiplayer = 'false') => {
    let response = {} as AxiosResponse;
    const axiosRequest = await AuthProvider.getAxiosInstance()
      .get(`/api/v1/lesson_instances/${id}/summary?multiplayer=${multiplayer}`)
      .then(function (a_response: any) {
        response = a_response;
      })
      .catch(function (error: any) {
        console.log(error);
      });

    if (response.status === 500) {
      throw new Error('Internal server error');
    }

    const data = response.data;
    if (response.status > 400 && response.status < 500) {
      if (data.detail) {
        throw data.detail;
      }
      throw data;
    }

    return data;
  },
  /**
   * Get summary of a match
   *
   * @returns JSON data containing match summary
   * @throws Error on http errors or failed attempts
   */
  getMatchSummary: async (id: string) => {
    let response = {} as AxiosResponse;
    const axiosRequest = await AuthProvider.getAxiosInstance()
      .get(`/api/v1/game/${id}/summary`)
      .then(function (a_response: any) {
        response = a_response;
      })
      .catch(function (error: any) {
        console.log(error);
      });

    if (response.status === 500) {
      throw new Error('Internal server error');
    }

    const data = response.data;
    if (response.status > 400 && response.status < 500) {
      if (data.detail) {
        throw data.detail;
      }
      throw data;
    }

    return data;
  },
  /**
   * Get summary of a survey from a match
   *
   * @returns JSON data containing match summary
   * @throws Error on http errors or failed attempts
   */
  getSurveySummary: async (id: string) => {
    let response = {} as AxiosResponse;
    const axiosRequest = await AuthProvider.getAxiosInstance()
      .get(`/api/v1/game/survey/${id}/summary`)
      .then(function (a_response: any) {
        response = a_response;
      })
      .catch(function (error: any) {
        console.log(error);
      });

    if (response.status === 500) {
      throw new Error('Internal server error');
    }

    const data = response.data;
    if (response.status > 400 && response.status < 500) {
      if (data.detail) {
        throw data.detail;
      }
      throw data;
    }

    return data;
  },
  /**
   * Get survey data for a classroom
   *
   * @returns JSON data containing match summary
   * @throws Error on http errors or failed attempts
   */
  getClassroomData: async (date: string) => {
    let response = {} as AxiosResponse;
    const axiosRequest = await AuthProvider.getAxiosInstance()
      .get(`/api/v1/game/classroom/${date}/data`)
      .then(function (a_response: any) {
        response = a_response;
      })
      .catch(function (error: any) {
        console.log(error);
      });

    if (response.status === 500) {
      throw new Error('Internal server error');
    }

    const data = response.data;
    if (response.status > 400 && response.status < 500) {
      if (data.detail) {
        throw data.detail;
      }
      throw data;
    }

    return data;
  },
  /**
   * Get player history
   *
   * @returns JSON data containing player history
   * @throws Error on http errors or failed attempts
   */
  getPlayerHistory: async (id: number) => {
    let response = {} as AxiosResponse;
    const axiosRequest = await AuthProvider.getAxiosInstance()
      .get(`/api/v1/game/player/${id}/history`)
      .then(function (a_response: any) {
        response = a_response;
      })
      .catch(function (error: any) {
        console.log(error);
      });

    if (response.status === 500) {
      throw new Error('Internal server error');
    }

    const data = response.data;
    if (response.status > 400 && response.status < 500) {
      if (data.detail) {
        throw data.detail;
      }
      throw data;
    }

    return data;
  }
};
